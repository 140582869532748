import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w06RS01Schnuppern = () => (
  <Layout>
    <SEO title="w06RS01Schnuppern" />

    <h1 id="ueberschrift">Fantasy-Rollenspiel</h1>
    <h2>Schnuppertreff für Interessierte &emsp;  ©&nbsp;1996</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w06RS01Schnuppern&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Ob als Mensch, Elf, Zwerg, Oger oder ein anderes Wesen: erleben Sie 
        bei einem gemütlichen Beisammensein eine fantastische Welt, indem Sie sie
        als Kämpfer, Gaukler, Magiekundiger, etc. "bereisen" und ihre Rätsel lösen.
        Lassen Sie ihrer Fantasie im Rahmen des Zusammenspiels und unter Anleitung eines
        "Spielleiters" (game master GM) freien Lauf 
        und genießen Sie bei lustigen und interessanten Anekdoten die gemeinsame Zeit.
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Sie können die Gelegenheit nutzen und einen ersten Eindruck gewinnen,
        indem Sie sich über die angegebene Kontaktmöglichkeit melden. Gemeinsam ermitteln wir dann den
        für Sie günstigsten Termin, damit Sie in das Themengebiet "hineinschnuppern" können.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Informationskurs (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Materialbedarf: + ggf. Würfel +
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig + <br/>
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w06RS01Schnuppern
